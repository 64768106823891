import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons'

const KeysCell = ({ setDataFiltered, data }) => {
    const { el, index, dataFiltered } = data;


    const [inputValue, setInputValue] = useState(el);

    // delete a selected keys in every file.
    const deleteKey = () => {
        setDataFiltered((oldValue) => {
            let temp = oldValue;
            temp.forEach((object) => {
                delete object.data[el];
            })
            return Object.assign([...oldValue], temp);
        })
    }


    
    // input change
    const handleChange = (e) => {
        setInputValue(e.target.value);
    }
    
    // on blur return an updated array with the new values
    const handleKeyBlur = () => {
        if (inputValue !== el) {
            setDataFiltered((prevData) => {

                let temp = prevData;
                let tempObj = prevData;
                temp.forEach((element, index) => {
                    tempObj[index].data = generateNew(element);
                })
                return Object.assign([...prevData], tempObj);
            })
        }
    }

    const generateNew = (arrItem) => {
        let temp = {};
        Object.keys(arrItem.data).forEach((item) => {
            if(item !== el){
                temp[item] = arrItem.data[item];
            } else {
                temp[inputValue] = arrItem.data[el];
            }
        })


        return temp;
    }

    return (
        <div>
            {/* <h3>{el}</h3> */}
            <input type="text" className={inputValue === "" | inputValue === " " ? "error" : ""} value={inputValue} onChange={e => handleChange(e)} onBlur={handleKeyBlur} />
            <FontAwesomeIcon icon={faSquareXmark} onClick={deleteKey} />
        </div>
    )
};

export default KeysCell;
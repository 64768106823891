import { faCloudArrowUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import LanguageSection from './Utilities/LanguageSection';
import JSZip from "jszip/dist/jszip";
import { saveAs } from 'file-saver';


const Main = () => {
	// useState
	const [select, setSelect] = useState(JSON.parse(localStorage.getItem("select")) || []);
	const [selected, setSelected] = useState(localStorage.getItem("selected") || 0);
	const [lang, setLang] = useState(JSON.parse(localStorage.getItem("lang")) || []);
	const [data, setData] = useState(JSON.parse(localStorage.getItem("data")) || {});

	//variables
	let files = [];
	const tempIndex = useRef(0);

	//regex
	const regexXX = /\/[A-Z]{2,3}\/[A-Z0-9]{1,}\.json/gi;
	const regexXX_XX = /\/[A-Z]{2}\-[A-Z]{2}\/[A-Z0-9]{1,}\.json/gi;
	const regexIT = /[A-Z]{2,3}/gi;
	const regexIT_it = /[A-Z]{2}\-[A-Z]{2}/gi;

	// JSZip configuration in order to download a zip file containing all the data based on the original directory
	const download = () => {
		var zip = new JSZip();
		lang.forEach((el, index) => {
			var lang = zip.folder(el);
			select.forEach(fileName => {
				lang.file(fileName, JSON.stringify(JSON.parse(localStorage.getItem(fileName + "_" + el)).data, null, "\t"), JSON);
			})
		})
		zip.generateAsync({ type: "blob" })
			.then(function (content) {
				saveAs(content, "Traduzioni.zip");
			});
	}

	// create a data variable that print the keys in the keys column
	const initData = () => {
		let tempData = [];
		if (localStorage.getItem("files")) {
			JSON.parse(localStorage.getItem("files")).forEach((el) => {
				if(lang.length > 0){
					lang.forEach((langElement) => {
						if (select[selected] + "_" + langElement === el) {
							tempData.push(JSON.parse(localStorage.getItem(el)));
						}
					})
				}
			})
			tempData.forEach((el, index) => {
				if (el.lang.match(regexIT) !== null || el.lang.match(regexIT_it) !== null) {
					tempData.splice(0, 0, el);
					tempData.splice(index + 1, 1);
				}
			})
		}
		setData(tempData);
		localStorage.setItem("data", JSON.stringify(tempData));
	}

	// when the selected change value the data displayed are changed
	useEffect(() => {
		initData();
	}, [selected])

	// save all data into local storage
	const saveALL = (data) => {
		data.forEach((el) => {
			lang.forEach(lang => {
				if (el.lang === lang) {
					localStorage.setItem(select[selected] + "_" + lang, JSON.stringify(el));
				}
			})
		})
	}

	// create an array of file name for the select
	const selectFiles = (object) => {
		let seen = new Set();
		let temp = Object.values(object);
		const filteredArr = temp.filter(el => {
			if (el.name !== ".DS_Store") {
				const duplicate = seen.has(el.name);
				seen.add(el.name);
				return !duplicate;
			}
		});
		const nameArray = [];
		filteredArr.forEach(el => {
			nameArray.push(el.name);
		})
		return nameArray;
	}

	
	useEffect(() => {
		localStorage.setItem("select", JSON.stringify(select));
	}, [select, selected])

	useEffect(() => {
		localStorage.setItem("selected", selected);
	}, [selected])

	useEffect(() => {
		setSelected(0);
		localStorage.setItem("selected", 0);
	}, [])

	// remove duplicates from an array of elements
	const eraseDuplicate = (array) => {
		console.log(array);
		const uniqueElements = new Set(array);
		return [...new Set(uniqueElements)]
	}

	//search the webkitrelativepath and find all the languages using a regex
	const getLangFiltered = (files) => {
		let temp = [];
		Object.values(files).forEach((el) => {
			if (el.name !== ".DS_Store") {
				if (el.webkitRelativePath.match(regexXX) !== null) {
					temp.push(el.webkitRelativePath.match(regexXX)[0].slice(1, 3));
				}
				if (el.webkitRelativePath.match(regexXX_XX) !== null) {
					temp.push(el.webkitRelativePath.match(regexXX_XX)[0].slice(1, 6));
				}
			}
		})
		temp = eraseDuplicate(temp);
		setLang(localStorage.setItem("lang", JSON.stringify(temp)));
	}
	const getLang = (files) => {
		let temp = [];
		Object.values(files).forEach((el) => {
			if (el.name === ".DS_Store") {
				temp.push("");
			} else {
				if (el.webkitRelativePath.match(regexXX) !== null) {
					temp.push(el.webkitRelativePath.match(regexXX)[0].slice(1, 3));
				}
				if (el.webkitRelativePath.match(regexXX_XX) !== null) {
					temp.push(el.webkitRelativePath.match(regexXX_XX)[0].slice(1, 6));
				}
			}
		})
		return temp;
	}

	// data are saved and manipulated in order to save all the data in local storage
	const handleChangeFile = (file) => {
		files = file.target.files;
		setSelect(selectFiles(files));
		let temp = selectFiles(files);
		getLangFiltered(files);
		console.log("oi")
		let tempLang = getLang(files);
		let tempArray = [];
		[...files].forEach((el, index) => {
			temp.forEach((item) => {
				if (el.name !== ".DS_Store") {
					if (item === el.name) {
						tempArray.push(item + "_" + tempLang[index]);
						let reader = new FileReader();
						reader.onload = function () {
							let tempLocal = {};
							tempLocal.lang = tempLang[index];
							tempLocal.data = JSON.parse(reader.result);
							localStorage.setItem(item + "_" + tempLang[index], JSON.stringify(tempLocal));
						};
						reader.readAsText(el);
					}
				}
			})
		});
		localStorage.setItem("files", JSON.stringify(tempArray));
		window.location.reload();
	}

	//clear localstorage and refresh page
	const handleClear = () => {
		localStorage.clear();
		window.location.reload();
	}

	// update a variable in order to refresh the data when the select change
	const saveButton = () => {
		tempIndex.current++;
	}

	return (
		<>
			<header className='header'>
				<div className='input-section'>
					<h3>1. Inserisci la cartella contenente i file JSON di traduzione</h3>
					<div className="button-container">
						<label htmlFor="file-upload" className="custom-button">
							<FontAwesomeIcon icon={faCloudArrowUp} /> Carica cartella
							<input id="file-upload" directory="" type="file" mozdirectory="" webkitdirectory="" onChange={handleChangeFile} />
						</label>
						<div className="custom-button clear-data" onClick={handleClear}>
							<FontAwesomeIcon icon={faTrash} /> Svuota dati caricati
						</div>
					</div>
					<div className="select-section">
						{
							select.length !== 0 && <>
								<h3>2. Seleziona il file da modificare:</h3>
								<select onClick={saveButton()} name="select" id="select" onChange={(e) => { setSelected(e.target.value) }}>
									{
										select.map((el, index) => {
											return <option key={index} value={index} >{el}</option>
										})
									}
								</select>
							</>
						}
					</div>
				</div>
				<h1>JSON comparator</h1>
			</header>
			<main className="main">
				{
					select.length !== 0 && data && <LanguageSection key={tempIndex.current} data={data} saveALL={saveALL} download={download} selected={selected} select={select} />
				}
			</main>
		</>
	)
}

export default Main
import React, { useState } from "react";

const Cell = ({ setDataFiltered, data }) => {
	const {el, index1, index, keys} = data;

	// useState
	const [inputValue, setInputValue] = useState(el);
	
	// input change
	const handleChange = (e) => {
		setInputValue(e.target.value);
	}

	// on blur return an updated array with the new values
	const handleBlur = () => {
		setDataFiltered((prevData) => {
			let temp = prevData;
			temp[index1].data[keys[index]] = inputValue;
			return Object.assign([...prevData], temp);
		})
	}


	return (
		<div>
			<input type="text" className={inputValue === "" | inputValue === " " ? "error" : ""} value={inputValue} onChange={e => handleChange(e)} onBlur={handleBlur}/>
		</div>
	)
};

export default Cell;

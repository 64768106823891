import React, { useState, useEffect, useRef} from "react";
import Cell from "./Cell";
import KeysCell from "./KeysCell";
import { ScrollSync, ScrollSyncNode } from 'scroll-sync-react';

const LanguageSection = ({ data, saveALL, selected, select, download }) => {

  // //useState
  const [dataFiltered, setDataFiltered] = useState([]);
  const [mainData, setMainData] = useState({});
  const [charged, setCharged] = useState(false);
	const [saved, setSaved] = useState(false);
  const [keyName, setKeyName] = useState("");

  // ref
  const refresh = useRef(0);
  
  // regex
    const regexIT = /it/gi;
    const regexITA = /ita/gi;
    const regexIT_it = /it-it/gi;

  //create an object with the remainig keys empty
  const valueComparator = (object, objective) => {
    let temp = {};
    Object.keys(objective).forEach((key) => {
      temp[key] = "";
      Object.keys(object).forEach((el) => {
        if (key === el) {
          temp[key] = object[el];
        }
      });
    });
    return temp;
  };

  // set useState and populate dataFiltered state
  useEffect(() => {
    let tempDataFiltered = [];
    let mainData = {}
    data.forEach((el) => {
      if (el.lang.match(regexIT) !== null || el.lang.match(regexIT_it) !== null || el.lang.match(regexITA) !== null) {
        mainData = el.data;
      }
    });
    data.forEach((el) => {
      tempDataFiltered.push({
        lang: el.lang,
        data: valueComparator(el.data, mainData),
      });
    });
    setDataFiltered(tempDataFiltered);
  }, [selected]);

// mainData variable for the keys column
  useEffect(() => {
    let tempMainData = {};
    dataFiltered.forEach((el) => {
      if (el.lang.match(regexIT) !== null || el.lang.match(regexIT_it) !== null || el.lang.match(regexITA) !== null) {
        tempMainData.lang = "IT"
        tempMainData.data = el.data;
      }
    });
    setMainData(tempMainData);
  }, [dataFiltered]);

  // add a new key
  const addKey = () => {
    setDataFiltered((oldValue) => {
      let temp = oldValue;
      temp.forEach((object) => {
          object.data = {[keyName]: "", ...object.data};
      })
      setKeyName("");
      return Object.assign([...oldValue], temp);
    })
  }


  useEffect(() => {
    if (dataFiltered.length > 0) {
      setCharged(true);
    }
    refresh.current++;
  }, [dataFiltered])



  return (
    <>
        <ScrollSync>
      <div className="div container-value">
        {charged > 0 && 
        <ScrollSyncNode group="a">
          <div className="keys">
            <div className="title keysTab" >
              <h3>
                Chiavi - <span className="uppercase">{mainData.lang}</span>
              </h3>
              <div className="addKeyBox">
                <input className="addKey" type="text" value={keyName} onChange={(e) => {setKeyName(e.target.value)}} placeholder="Inserisci il nome della nuova chiave"/>
                <button className="addKey" onClick={addKey}>Nuova key</button>
              </div>
            </div>
            <div className="content-box">
              <div className="row">
                {Object.keys(mainData.data).map((el, index) => {
                  return <KeysCell key={index} setDataFiltered={setDataFiltered} data={{ el: el, index: index, dataFiltered }} />
                })}
              </div>
            </div>
          </div>
        </ScrollSyncNode>
        }
        {dataFiltered.map((element, index1) => {
          return (
            <ScrollSyncNode group="a" key={index1}>
            <div className="keys">
              <div className="title" >
                <h3>
                  Traduzione - <span className="uppercase">{element.lang}</span>
                </h3>
              </div>
              <div className="content-box">
                <div className="row values" key={refresh.current}>
                  {Object.values(element.data).map((el, index) => {
                    return <Cell key={index} setDataFiltered={setDataFiltered} data={{ el: el, index1: index1, index: index, keys: Object.keys(element.data) }} />
                  })}
                </div>
              </div>
            </div>
            </ScrollSyncNode>
          );
        })}
      </div>
      </ScrollSync>
      <div className="bottom-section">
        <button className={saved ? "saved" : ""}
          onClick={() => {
            saveALL(dataFiltered);
            setSaved(true);
            setTimeout(() => {
              setSaved(false);
            }, 2000);
          }}
        >
          {saved ? "Modifiche salvate" : "Salva modifiche"}
        </button>
        <button
          onClick={() => {
            download();
          }}
        >
          Scarica file .ZIP
        </button>
      </div>
    </>
  );
};

export default LanguageSection;
